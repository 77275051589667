<template>
  <div class="app-container">
    <!-- 商户页面开始 -->
    <div v-if="switchPag===0">
      <!-- 搜索组件 -->
      <div>
        <!--高级搜索输入框开始 -->
        <div v-if="show">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="timeValue"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions2"
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <!--高级搜索输入框结束 -->
        <div class="searchheader">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="商户">
              <el-input v-model="nameValue" placeholder="请输入商户名" :disabled="disableds"></el-input>
            </el-form-item>
            <el-form-item label="商户号">
              <el-input v-model="seachShopId" placeholder="请输入商户号"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="stateValue" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round plain @click="search" icon="el-icon-search">搜索</el-button>
              <el-button
                @click="showInput"
                plain
                type="primary"
                :icon="show ? 'el-icon-zoom-out':'el-icon-zoom-in'"
                round
                style="border:0;margin-left:10px"
              >高级搜索</el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-button
              type="primary"
              plain
              @click="addEngineerList"
              round
              icon="el-icon-circle-plus"
            >创建</el-button>
          </div>
        </div>

        <!-- 创建商户弹窗开始 -->
        <!-- 弹框 -->
        <el-dialog title="创建商户" :visible.sync="dialogForm" width="30%">
          <el-form :model="addEngineer" :rules="rules" ref="addEngineer">
            <el-form-item
              label="名称"
              :label-width="formLabelWidths"
              prop="shopName"
            >
              <el-input v-model="addEngineer.shopName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              :label-width="formLabelWidths"
              prop="mobile"
            >
              <el-input v-model="addEngineer.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="地址"
              :label-width="formLabelWidths"
              prop="address"
            >
              <el-input v-model="addEngineer.address" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="详情" :label-width="formLabelWidths">
              <el-input v-model="addEngineer.remark" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="服务商"
              :label-width="formLabelWidths"
              v-if="this.$store.state.user.level===1"
              prop="engineerValue"
            >
              <el-select v-model="addEngineer.engineerValue" clearable filterable remote :remote-method="remoteMethod" placeholder="请输入关键字查询">
                <el-option
                  v-for="item in engineer"
                  :key="item.id"
                  :label="item.label"
                  :value="item.projectName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="密钥" :label-width="formLabelWidths">
              <el-input value="********" auto-complete="off" :disabled="true"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogForm = false">取 消</el-button>
            <el-button type="primary" @click="addData('addEngineer')">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 创建商户弹窗结束 -->
      </div>
      <!-- 表格部分 -->
      <div>
        <el-table
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableDataList"
          style="width:100%;"
          :height="this.show ?640:700"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="商户名称">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.shopName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地址">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column label="商户号" width="110">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属服务商">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.projectContractorIds }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column label="详情" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                plain
                type="primary"
                @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="warning"
                plain
                @click="SwitchState(scope.row)"
              >{{scope.row.Disable}}</el-button>
              <el-button
                size="mini"
                plain
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button>
              <!-- 屏蔽 -->
              <!-- <el-button
                size="mini"
                plain
                type="success"
                @click="CollectionChannels(scope.$index, scope.row)"
              >收款渠道</el-button>
              <el-button size="mini" plain type="info" @click="DataCompletion(scope.row)">资料补全</el-button>-->
              <el-button size="mini" type="success" plain @click="setEngineer(scope.row)">设置</el-button>
              <el-button size="mini" type="info" plain @click="uploadProject(scope.row)" v-if="user.level==1">变更服务商</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 编辑商户弹窗开始 -->
        <el-dialog title="编辑商户" :visible.sync="ManagementEdits" width="48%">
          <div class="editShop">
            <el-form :model="tabelEdit" ref="tabelEdit" style="width:49%">
              <el-form-item
                label="名称"
                :label-width="formLabelWidth"
                prop="shopName"
                :rules="[{ required: true,  message: '名称不能为空', trigger: 'blur' }]"
              >
                <el-input v-model="tabelEdit.shopName" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item label="电话" :label-width="formLabelWidth">
                <el-input v-model="tabelEdit.mobile" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item label="详情" :label-width="formLabelWidth">
                <el-input v-model="tabelEdit.remark" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item label="地址" :label-width="formLabelWidth">
                <el-input v-model="tabelEdit.address" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item label="密钥" :label-width="formLabelWidth">
                <el-input v-model="tabelEdit.secretKey" auto-complete="off" :readonly="true"></el-input>
              </el-form-item>
              <el-form-item
                label="用户状态变更地址"
                :label-width="formLabelWidth_address_02"
                prop="userStatusNotifyUrl"
                :rules="[{ required: true, pattern: /^(http|https):\/\/([\w.]+\/?)\S*$/, message: '请输入http/https开头的地址', trigger: 'blur' }]"
              >
                <el-input
                  v-model="tabelEdit.userStatusNotifyUrl"
                  auto-complete="off"
                  :readonly="true"
                  type="textarea"
                  style="width:92%"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="默认入账通道"
                label-width="110px"
                prop="settlementChannel"
                :rules="[{ required: true,  message: '默认入账通道不能为空', trigger: 'change' }]"
              >
                <el-select v-model="tabelEdit.settlementChannel" placeholder="请选择">
                  <el-option label="支付宝直连" :value="1"></el-option>
                  <el-option label="微信直连" :value="2"></el-option>
                  <el-option label="云纵" :value="3"></el-option>
                  <el-option label="昆山农商行" :value="4"></el-option>
                  <el-option label="未开通支付" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div v-show="tabelEdit.settlementChannel!==0" style="width:49%">
              <div class="paytype">
                <div style="width:99px;">支付宝主扫&nbsp;</div>
                <div>
                  <el-radio
                    v-model="tabelEdit.aliScanfDefaultChannel"
                    :label="1"
                  >开通&nbsp;&nbsp;&nbsp;</el-radio>
                  <el-radio v-model="tabelEdit.aliScanfDefaultChannel" :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.aliScanfDefaultChannel" :label="0">不支持</el-radio>
                </div>
              </div>
              <div class="paytype">
                <div style="width:99px;">微信主扫&nbsp;</div>
                <div>
                  <el-radio
                    v-model="tabelEdit.wxScanfDefaultChannel"
                    :label="1"
                  >开通&nbsp;&nbsp;&nbsp;</el-radio>
                  <el-radio v-model="tabelEdit.wxScanfDefaultChannel" :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.wxScanfDefaultChannel" :label="0">不支持</el-radio>
                </div>
              </div>
              <div class="paytype">
                <div style="width:99px">支付宝被扫</div>
                <div>
                  <el-radio v-model="tabelEdit.aliBeScanfDefaultChannel" :label="1">开通</el-radio>
                  <el-radio v-model="tabelEdit.aliBeScanfDefaultChannel" :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.aliBeScanfDefaultChannel" :label="0">不支持</el-radio>
                </div>
              </div>
              <div class="paytype">
                <div style="width:99px">微信被扫</div>
                <div>
                  <el-radio v-model="tabelEdit.wxBeScanfDefaultChannel" :label="1">开通</el-radio>
                  <el-radio v-model="tabelEdit.wxBeScanfDefaultChannel" :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.wxBeScanfDefaultChannel" :label="0">不支持</el-radio>
                </div>
              </div>
              <div class="paytype">
                <div style="width:99px">支付宝无感</div>
                <div>
                  <el-radio v-model="tabelEdit.aliInsensableDefaultChannel" :label="1">开通</el-radio>
                  <el-radio v-model="tabelEdit.aliInsensableDefaultChannel" :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.aliInsensableDefaultChannel" :label="0">不支持</el-radio>
                </div>
              </div>
              <div class="paytype">
                <div style="width:99px">微信无感</div>
                <div>
                  <el-radio v-model="tabelEdit.wxInsensableDefaultChannel " :label="1">开通</el-radio>
                  <el-radio v-model="tabelEdit.wxInsensableDefaultChannel " :label="-1">不开通</el-radio>
                  <el-radio v-model="tabelEdit.wxInsensableDefaultChannel " :label="0">不支持</el-radio>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="sureEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑商户弹窗结束 -->
        <!-- 删除成功弹窗开始 -->
        <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDel" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleDel = false">取 消</el-button>
            <el-button type="primary" @click="SureDel">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除成功弹窗结束 -->
        <!-- 删除成功弹窗开始 -->
        <el-dialog title="变更服务商" :visible.sync="dialogUploadProject" width="25%">
            <el-select v-model="newProjectId" placeholder="请选择">
              <el-option
                v-for="item in projectAllData"
                :key="item.value"
                :label="item.projectName"
                :value="item.id"
              ></el-option>
            </el-select>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogUploadProject = false">取 消</el-button>
            <el-button type="primary" @click="confirmUpload">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除成功弹窗结束 -->
        <!-- 资料补全弹框开始 -->
        <el-dialog title="提交历史" :visible.sync="dialogTabledata" width="30%" center>
          <div
            style="display:flex;justify-content: space-around"
            v-for="(item,index) in SubmitData"
            :key="index"
          >
            <p style="border-bottom:1px solid;padding-bottom:5px;">{{item.fileName}}</p>
            <p>{{item.createTime}}</p>
            <p class="down" @click="downloadData(item)">下载</p>
          </div>
          <div>
            <el-upload
              ref="upload"
              class="upload-demo"
              action="/api/shop/uploadMaterial"
              :file-list="fileList"
              :auto-upload="false"
              :limit="1"
              accept=".xlsx, .txt, .docx"
              :data="uploadData"
              :with-credentials="true"
              :headers="headers"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xlsx,txt,docx格式的文件</div>
            </el-upload>
            <el-input
              v-model="fileInput"
              size="mini"
              placeholder="请添加备注"
              style="width:34%;margin-top: 5px; "
            ></el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogTabledata = false">取 消</el-button>
            <el-button type="primary" @click="submitUpload">提交</el-button>
          </span>
        </el-dialog>
        <!-- 资料补全弹框结束 -->
      </div>
      <!-- 表格结束 -->
      <div style="text-align:right">
        <Paging :totalSize="totalSize" :getMethods="getMerchantDate" />
      </div>
    </div>
    <!-- 商户页面结束 -->

    <!-- 收款渠道页面开始 -->
    <div v-else-if="switchPag===1">
      <div style="width:100%;text-align:right;">
        <el-button type="primary" size="mini" plain round @click="ReturnEdit">返回</el-button>
      </div>
      <!-- 微信，支付宝开始 -->
      <div>
        <!-- 微信直连 -->
        <div style="margin-top:20px;" v-if="this.CollectionChannelsData.settlementChannel==2">
          <div class="tabelhader">
            <svg
              t="1581573688366"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4742"
              width="32"
              height="32"
            >
              <path
                d="M490.214141 399.773737c9.309091 0 18.230303-3.749495 24.69495-10.343434 6.464646-6.464646 10.084848-15.385859 10.084848-24.694949 0-9.179798-3.620202-18.10101-10.084848-24.69495-6.593939-6.593939-15.385859-10.343434-24.69495-10.343434-9.309091 0-18.230303 3.749495-24.694949 10.343434-6.464646 6.593939-10.084848 15.515152-10.084849 24.565657-0.129293 9.309091 3.620202 18.230303 10.084849 24.824242s15.385859 10.343434 24.694949 10.343434zM317.478788 329.69697c-19.264646 0-34.909091 15.644444-34.909091 35.038384 0 9.179798 3.620202 18.10101 10.084848 24.694949 6.593939 6.593939 15.385859 10.343434 24.69495 10.343434 19.264646 0 34.909091-15.773737 34.909091-35.038383 0.129293-9.179798-3.620202-18.10101-10.084849-24.69495-6.464646-6.723232-15.385859-10.343434-24.694949-10.343434zM725.39798 503.208081c-17.066667 0-30.771717 12.929293-30.771717 28.832323 0 16.032323 13.834343 28.961616 30.771717 28.961616 17.066667 0 30.771717-12.929293 30.771717-28.961616 0-15.90303-13.705051-28.832323-30.771717-28.832323zM572.70303 503.208081c-17.066667 0-30.771717 12.929293-30.771717 28.832323 0 16.032323 13.834343 28.961616 30.771717 28.961616 17.066667 0 30.771717-12.929293 30.771717-28.961616 0.129293-15.90303-13.705051-28.832323-30.771717-28.832323z"
                fill="#3CB034"
                p-id="4743"
              />
              <path
                d="M775.434343 93.090909H248.565657C162.973737 93.090909 93.414141 162.650505 93.414141 248.242424v526.868687c0 85.591919 69.559596 155.151515 155.151516 155.151515h526.868686c85.591919 0 155.151515-69.559596 155.151516-155.151515V248.242424c0-85.591919-69.430303-155.151515-155.151516-155.151515zM325.365657 644.137374l-4.266667-1.29293L231.369697 690.424242l24.048485-78.222222-2.327273-1.422222c-59.216162-39.434343-97.486869-101.624242-97.486869-171.442424 0-119.208081 111.062626-215.919192 248.113132-215.919192 124.121212 0 226.909091 79.385859 245.268687 182.820202-3.361616-0.258586-6.981818-0.258586-10.472728-0.258586-124.89697 0-226.00404 88.177778-225.874747 197.30101 0 17.454545 2.715152 34.909091 7.886869 51.458586-32.064646 1.939394-64.129293-1.680808-95.159596-10.60202z m456.791919 90.50505l-1.939394 1.29293 21.20404 64.387878-79.385858-39.175757c-23.660606 6.723232-48.355556 10.084848-73.050505 10.084848C527.838384 771.232323 429.575758 691.717172 429.575758 593.454545s98.262626-177.777778 219.410101-177.777777c121.147475 0 219.280808 79.644444 219.280808 177.777777 0.129293 57.406061-33.616162 108.606061-86.109091 141.187879z"
                fill="#3CB034"
                p-id="4744"
              />
            </svg>
            微信直连
          </div>
          <div>
            <el-table border :data="weChat" style="width:100%;" :row-class-name="tableRowClassName">
              <el-table-column label="渠道名称">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.mchName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="微信特约子商户号">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.subMchId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否授权">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.authorized }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.examined }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.state }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" plain type="primary" @click="weChatEdit(scope.row)">编辑</el-button>
                  <el-button size="mini" plain type="danger" @click="weChatDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 支付宝直连 -->
        <div style="margin-top:20px;" v-if="this.CollectionChannelsData.settlementChannel==1">
          <div class="tabelhader">
            <svg
              t="1581573721540"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5499"
              width="32"
              height="32"
            >
              <path
                d="M912.032 659.67999969L912.032 265.82400031c0-84.89599969-68.928-153.82399969-153.888-153.82399969L265.79199969 111.99999969c-84.89599969 0-153.82399969 68.8960003-153.82399969 153.82399969l0 492.384c0 84.89599969 68.86399969 153.82399969 153.8239997 153.82399968l492.38399999 0c75.67999969 0 138.624-54.72 151.45600031-126.65599968-40.8-17.664-217.60000031-94.01599969-309.72799969-137.98399969-70.11199969 84.92800031-143.52 135.87199969-254.17600031 135.87199969s-184.51199999-68.16-175.64800031-151.55200032c5.856-54.75199969 43.392-144.288 206.52799968-128.928 85.95199969 8.064 125.31199969 24.12799969 195.39199969 47.26399969 18.144-33.216 33.216-69.85600031 44.64-108.768L305.792 441.27999969l0-30.816 153.792 0 0-55.296L272 355.16799969l1e-8-33.91999969 187.61599968 0L459.61599969 241.376c0 0 1.69600031-12.48 15.48799968-12.48000001l76.92799969 1e-8 0 92.32000031 199.99999969 0 0 33.91999969-199.99999969 1e-8 0 55.29599999 163.16800032 0c-15.0079997 61.056-37.75999969 117.15199969-66.27199969 166.14400031C696.32 593.72799969 912.032 659.67999969 912.032 659.67999969L912.032 659.67999969 912.032 659.67999969 912.032 659.67999969zM333.47199968 730.43199969c-116.928 0-135.42400031-73.824-129.21599999-104.67199968 6.144-30.68800031 40.00000031-70.78399969 105.024-70.7839997 74.688 0 141.6 19.16800031 221.92000031 58.20799969C474.78399969 686.68800031 405.47199969 730.43199969 333.47199968 730.43199969L333.47199968 730.43199969 333.47199968 730.43199969zM333.47199968 730.43199969"
                fill="#1296db"
                p-id="5500"
              />
            </svg>
            支付宝直连
          </div>
          <div>
            <el-table border :data="alipay" style="width:100%;" :row-class-name="tableRowClassName">
              <el-table-column label="渠道名称" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.mchName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="支付宝商户号" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.mchId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否授权" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.authorized }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.examined }}</span>
                </template>
              </el-table-column>
              <el-table-column label="商户授权码" width="200">
                <template slot-scope="scope">
                  <span
                    style="margin-left: 10px"
                  >{{ scope.row.appAuthToken?'********':scope.row.appAuthToken }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.state }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" plain type="primary" @click="alipayEdit(scope.row)">编辑</el-button>
                  <el-button size="mini" plain type="danger" @click="alipayDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 云纵 -->
        <div style="margin-top:20px;" v-if="this.CollectionChannelsData.settlementChannel==3">
          <div class="tabelhader">
            <img src="../../assets/img/yunz.jpg" alt="云纵" />
            云纵
          </div>
          <div>
            <el-table border :data="yzData" style="width:100%;" :row-class-name="tableRowClassName">
              <el-table-column label="渠道名称" width="300">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.mchName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="子商户号" width="250">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.subMchId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" width="250">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否授权" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.authorized }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.examined }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.state }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" plain type="primary" @click="yzEdit(scope.row)">编辑</el-button>
                  <el-button size="mini" plain type="danger" @click="yzDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 昆山 -->
        <div style="margin-top:20px;" v-if="this.CollectionChannelsData.settlementChannel==4">
          <div class="tabelhader">昆山农商行</div>
          <div>
            <el-table
              border
              :data="ksbData"
              style="width:100%;"
              :row-class-name="tableRowClassName"
            >
              <el-table-column label="渠道名称" width="300">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.mchName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="子商户号" width="250">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.subMchId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" width="250">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否授权" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.authorized }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.examined }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="200">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.state }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" plain type="primary" @click="yzEdit(scope.row)">编辑</el-button>
                  <el-button size="mini" plain type="danger" @click="yzDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 编辑微信弹窗开始 -->
        <el-dialog title="编辑微信直连" :visible.sync="CollectMoney" width="30%">
          <el-form :model="weChatEditData">
            <el-form-item label="所属商户" :label-width="formLabelWidth">
              <el-input v-model="weChatEditData.mchName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="商户号" :label-width="formLabelWidth">
              <el-input
                v-model="weChatEditData.subMchId"
                auto-complete="off"
                :disabled="wxdisabledData"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="CollectMoney = false">取 消</el-button>
            <el-button type="primary" @click="weChatSureEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑弹窗结束 -->
        <!-- 删除微信直连弹窗开始 -->
        <el-dialog title="删除微信直连" top="30vh" :visible.sync="dialogDelete" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDelete = false">取 消</el-button>
            <el-button type="primary" @click="weChatSureDel">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除微信直连弹窗结束 -->
        <!-- 编辑支付宝弹窗开始 -->
        <el-dialog title="编辑支付宝直连" :visible.sync="alipayCollectMoney" width="30%">
          <el-form :model="alipayEditData">
            <el-form-item label="所属商户" :label-width="formLabelWidth">
              <el-input v-model="alipayEditData.mchName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="商户号" :label-width="formLabelWidth">
              <el-input
                v-model="alipayEditData.mchId"
                auto-complete="off"
                :disabled="apliydisabledData"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="alipayCollectMoney = false">取 消</el-button>
            <el-button type="primary" @click="alipaySureEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑支付宝弹窗结束 -->
        <!-- 删除支付宝直连弹窗开始 -->
        <el-dialog title="删除支付宝直连" top="30vh" :visible.sync="alipaydialogDelete" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="alipaydialogDelete = false">取 消</el-button>
            <el-button type="primary" @click="alipaySureDel">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 编辑云纵弹窗开始 -->
        <el-dialog title="编辑云纵直连" :visible.sync="yzCollectMoney" width="30%">
          <el-form :model="yzEditData">
            <el-form-item label="所属商户" :label-width="formLabelWidth">
              <el-input v-model="yzEditData.mchName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="子商户号" :label-width="formLabelWidth">
              <el-input
                v-model="yzEditData.subMchId"
                auto-complete="off"
                :disabled="yzdisabledData"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="yzCollectMoney = false">取 消</el-button>
            <el-button type="primary" @click="yzSureEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑云纵弹窗结束 -->
        <!-- 删除云纵直连弹窗开始 -->
        <el-dialog title="删除云纵直连" top="30vh" :visible.sync="yzdialogDelete" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="yzdialogDelete = false">取 消</el-button>
            <el-button type="primary" @click="yzSureDel">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除云纵直连弹窗结束 -->
      </div>
      <!-- 微信支付宝结束 -->
    </div>
    <!-- 收款渠道页面结束 -->

    <!-- 员工管理开始 -->
    <div v-else>
      <div style="text-align:right">
        <el-button size="mini" type="primary" plain @click="ReturnEdit" round>返回</el-button>
      </div>

      <!-- 搜索 -->
      <div style="text-align:right;margin-bottom:20px;margin-top:20px;">
        <el-button type="primary" plain @click="addEmployees" round style="margin-right:50px;">新增</el-button>
      </div>
      <!-- 创建员工弹框 -->
      <el-dialog title="新增员工" :visible.sync="FormVisible" width="30%">
        <el-form :model="addPersonnelData">
          <el-form-item
            label="名称"
            :label-width="formLabelWidth"
            prop="nickName"
            :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelData.nickName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="电话"
            :label-width="formLabelWidth"
            prop="mobile"
            :rules="[{ required: true, message: '电话不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelData.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            :label-width="formLabelWidth"
            prop="password"
            :rules="[{ required: true, message: '密码不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelData.password" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addPersonnel">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 搜索栏结束 -->
      <!-- 表格栏开始 -->
      <div>
        <el-table
          border
          :data="EmployeeData"
          style="width: 100%;height:650px"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登录账号" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.roleId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别" width="150">
            <!-- <template slot-scope="scope"> -->
            <span style="margin-left: 10px">男</span>
            <!-- </template> -->
          </el-table-column>
          <el-table-column label="最近登录" width="248">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="EmployeeEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="EmployeeDelete(scope.row)">删除</el-button>
              <el-button size="mini" type="success" plain @click="setPwd(scope.row)">重置密码</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 删除弹窗开始 -->
        <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDelete" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleDelete = false">取 消</el-button>
            <el-button type="primary" @click="DelSure">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除弹窗结束 -->
        <!-- 重置密码弹窗开始 -->
        <el-dialog title="重置密码" top="30vh" :visible.sync="dialogVisibleSet" width="15%">
          <div style="margin-bottom:20px;">
            <el-input placeholder="请输入需要修改的密码" v-model="pwd" clearable></el-input>
          </div>
          <el-input placeholder="请再次确认密码" v-model="pwdTwo" clearable></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSet = false">取 消</el-button>
            <el-button type="primary" @click="SureSetPwd">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 重置密码弹窗结束 -->
        <!-- 编辑员工开始 -->
        <el-dialog title="编辑员工" :visible.sync="EmployeeEdits" width="30%">
          <el-form :model="EmployeesList">
            <el-form-item label="* 名称" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.nickname" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="电话" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="角色" :label-width="formLabelWidth">
              <el-input placeholder="请输入内容" v-model="EmployeesList.roleId" :disabled="true"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="EmployeeEdits = false">取 消</el-button>
            <el-button type="primary" @click="DetermineEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑员工结束 -->
        <!-- 重置密码弹窗开始 -->
        <el-dialog title="重置密码" top="30vh" :visible.sync="dialogVisibleSet" width="15%">
          <div style="margin-bottom:20px;">
            <el-input placeholder="请输入需要修改的密码" v-model="pwd" clearable></el-input>
          </div>
          <el-input placeholder="请再次确认密码" v-model="pwdTwo" clearable></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSet = false">取 消</el-button>
            <el-button type="primary" @click="SureSetPwd">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 重置密码弹窗开始 -->
      </div>
      <!-- 表格栏结束 -->
    </div>
    <!-- 员工管理结束 -->
  </div>
</template>

<script>
import Paging from "../../components/Paging/Paging.vue";
import { getToken } from "../../utils/auth.js";
export default {
  components: {
    Paging
  },
  data() {
    return {
      user:{},
      loading: true,
      radio: 1, //
      show: false, // 高级查询
      switchPag: 0, //页面切换
      tableDataList: [],
      Permissions: [], //权限
      tabelEdit: {}, //编辑商户
      ManagementEdits: false, //编辑商户
      dialogVisibleDel: false, //删除提示窗框
      formLabelWidth: "80px", //弹框输入框宽度
      formLabelWidthwx: "108px",
      formLabelWidthapliy: "123px",
      input: "",
      value: "",
      Numbering: "",
      dialogForm: false,
      addEngineer: { shopName: "", mobile: "", address: "", remark: "",engineerValue:'' },
      engineer: [], //所有工程商
      DeleteData: {}, //删除商户
      dialogTabledata: false, //资料补全弹窗
      SubmitData: [],
      // 文件上传开始
      fileList: [], //上传文件列表
      fileInput: "", //上传文件输入框value
      roleId: 0, //商户roleId
      formLabelWidth_address: "165px",
      formLabelWidth_address_02: "135px",
      formLabelWidths: "80px",
      headers: {
        token: getToken().Token
      },
      CompletionMan: {},
      // 文件上传结束

      // 员工页面
      Numbering: "", //编号
      FormVisible: false, //创建员工弹窗
      receivablesValue: "角色", //支付方式状态值
      addPersonnelData: {}, //创建员工
      EmployeeData: [], //员工
      dialogVisibleDelete: false, //删除员工提示弹窗
      dialogVisibleSet: false, //重置密码提示弹窗
      pwd: "", //密码
      pwdTwo: "", //确认密码
      pwdEdit: {}, //修改密码数据
      EmployeeEdits: false, //编辑员工弹窗
      EmployeesList: {}, //编辑员工数据
      managementData: {}, //设置员工的商户
      roleALL: [], //所有角色
      options: [
        {
          value: "1",
          label: "可用"
        },
        {
          value: "0",
          label: "不可用"
        },
        {
          value: "",
          label: "无"
        }
      ],
      receivables: [
        {
          value: "1",
          label: "微信"
        },
        {
          value: "2",
          label: "支付宝"
        }
      ],
      nameValue: "", //商户搜索框
      stateValue: "", //商户状态搜索框
      disableds: false, //高级搜索打开时姓名输出框是否禁用
      seachShopId: "", //商户号搜索框值
      // 商户收款渠道
      CollectionChannelsData: {}, //点击跳转到商户渠道的商户
      weChat: [], //微信收款渠道
      alipay: [], //支付宝收款渠道
      yzData: [], //云纵收款渠道
      ksbData: [], //昆山收款渠道
      CollectMoney: false, //编辑收款渠道弹窗
      weChatEditData: {}, //编辑微信收款渠道数据
      weChatDelData: {}, //删除微信直连
      dialogDelete: false, //微信删除提示框
      alipayCollectMoney: false, //编辑支付宝直连
      alipayEditData: {}, //编辑支付宝数据
      yzCollectMoney: false, //编辑yunz 直连
      yzEditData: {}, //编辑云纵数据
      yzdialogDelete: false, //删除云纵
      yzCollectMoney: false, //编辑云纵
      alipaydialogDelete: false, //删除支付宝弹框
      alipayDelData: {}, //删除支付宝数据
      yzDelData: {}, //删除云纵数据
      wxdisabledData: true, //微信商户号编辑权限
      apliydisabledData: true, //支付宝商户号编辑权限
      yzdisabledData: true, //云纵商户号编辑权限

      timeValue: null, //商户选中的日期
      // 日期选择开始
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      totalSize: 0, // 分页
      pageDatalist: {},

      dialogUploadProject: false, //变更服务商
      projectAllData: [],//获取所有的工程商
      newProjectId:'',//变更服务商选中的数据


      rules:{
        shopName:[{ required: true, message: '名称不能为空', trigger: 'blur' }],
        mobile:[{ required: true, message: '电话不能为空', trigger: 'blur' }],
        address:[{ required: true, message: '地址不能为空', trigger: 'blur' }],
        engineerValue:[{ required: true, message: ' ', trigger: 'change' }]
      }
    };
  },
  created(){
    this.user =JSON.parse(sessionStorage.getItem('user'));
  },
  computed: {
    uploadData() {//文件上传数据
      return {
        projectId: this.CompletionMan.projectContractorId,
        shopId: this.CompletionMan.id,
        remark: this.fileInput
      };
    }
  },
  methods: {
    //获取商户
    getMerchantDate(pageData) {
      // 时间转换
      this.pageDatalist = { ...pageData };
      let datalists = {
          pageConfig: {
            orderType: "desc",
            pageSize: this.pageDatalist.pageSize,
            orderField: "id",
            pageNum: this.pageDatalist.pageNum
          },
          baseBean:{
            state: this.stateValue,
            id: this.seachShopId
          }
        };
      // 判断有没有进行高级搜索，有就传入对应的时间，没有就不传
      if (this.timeValue!== null) {
        datalists.searchBeans=[
            //高级查询条件
            {
              fieldName: "createTime", //字段
              startValue: this.timeValue[0]/1000, //值
              endValue:this.timeValue[1]/1000,
              operator: "BETWEEN" //大于等于
            },
            {
              fieldName: "state", //字段
              startValue: "0", //值
              operator: "GREATER_THAN_AND_EQUAL" //大于等于
            }
          ];
      } else {
        if (this.nameValue !== "") {
          datalists.searchBeans=[
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              },
              {
                fieldName: "shopName", //查询字段
                basicValue: this.nameValue, //值
                operator: "LIKE" //模糊查询
              }
            ]
        } else {
          datalists.searchBeans= [
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              }
            ]
        }
      }
      this.$axios.post("shop/query", datalists).then(res => {
        this.loading = false;
        this.totalSize = res.data.count; //总条数
        let datalist = res.data.shopTbPage; //返回数据
        let number =
          (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
        datalist.map(item => {
          number++;
          item.num = number;
          item.projectContractorIds =
            res.data.projectTbMap[item.projectContractorId];
          item.createTime = this.getTiem(item.createTime);
          if (item.state === 1) {
            item.state = "可用";
            item.Disable = "禁用";
          } else if (item.state === 0) {
            item.state = "不可用";
            item.Disable = "启用";
          } else {
            item.state = "禁用";
          }
          return item;
        });
        this.tableDataList = datalist;
      });
      this.seachShopId = "";
      this.nameValue = "";
    },
    // 搜索商户
    search() {
      this.pageDatalist.pageNum=1;
      this.getMerchantDate(this.pageDatalist);
    },
    //商户编辑
    handleEdit(index, row) {
      this.tabelEdit = { ...row };
      this.ManagementEdits = true;
    },
    // 关闭编辑弹框
    close() {
      this.ManagementEdits = false;
    },
    // 确认编辑
    sureEdit() {
      const {
        shopName,
        mobile,
        remark,
        address,
        id,
        projectContractorId,
        secretKey,
        payNotifyUrl,
        userStatusNotifyUrl,
        aliScanfDefaultChannel,
        wxScanfDefaultChannel,
        wxBeScanfDefaultChannel,
        aliBeScanfDefaultChannel,
        aliInsensableDefaultChannel,
        wxInsensableDefaultChannel,
        settlementChannel
      } = this.tabelEdit;
      if (shopName !== "" && secretKey !== "") {
        this.$axios
          .get("/shop/edit", {
            params: {
              shopId: id,
              projectId: projectContractorId,
              remark,
              secretKey,
              mobile: mobile !== "" ? mobile.trim() : "",
              address: address !== "" ? address.trim() : "",
              shopName: shopName !== "" ? shopName.trim() : "",
              payNotifyUrl,
              userStatusNotifyUrl,
              aliScanfDefaultChannel,
              wxScanfDefaultChannel,
              wxBeScanfDefaultChannel,
              aliBeScanfDefaultChannel,
              aliInsensableDefaultChannel,
              wxInsensableDefaultChannel,
              settlementChannel
            }
          })
          .then(res => {
            if (res.data.state == 1) {
              this.DeleteSuccess();
              this.pageDatalist.pageNum=1;
              this.getMerchantDate(this.pageDatalist);
              this.ManagementEdits = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    //禁启用商户
    SwitchState(row) {
      const { projectContractorId, id } = row;
      if (row.Disable === "禁用") {
        this.$axios
          .get("shop/disable", {
            params: { projectId: projectContractorId, shopId: id }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.pageDatalist.pageNum=1;
              this.getMerchantDate(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      } else if (row.Disable === "启用") {
        this.$axios
          .get("shop/enable", {
            params: { projectId: projectContractorId, shopId: id }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.pageDatalist.pageNum=1;
              this.getMerchantDate(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      }
    },
    //删除商户
    handleDelete(index, row) {
      this.dialogVisibleDel = true;
      this.DeleteData = row;
    },
    //删除员工
    EmployeeDelete(row) {
      this.dialogVisibleDelete = true;
      this.Employees = row;
    },
    //确认删除
    SureDel() {
      this.$axios
        .get("/shop/delete", {
          params: {
            shopId: this.DeleteData.id,
            projectId: this.DeleteData.projectContractorId
          }
        })
        .then(res => {
          if (res.data.state == 1) {
            this.DeleteSuccess();
            this.pageDatalist.pageNum=1;
            this.getMerchantDate(this.pageDatalist);
            this.dialogVisibleDel = false;
          } else {
            this.dialogVisibleDel = false;
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //删除成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //删除失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    //创建商户
    addEngineerList() {
      this.dialogForm = true;
    },
    //确认新增商户
    addData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let projectId;
          if (this.$store.state.user.level === 1) {
            this.engineer.forEach(item => {
              if (item.projectName == this.addEngineer.engineerValue) {
                projectId = item.id;
              }
            });
          } else {
            projectId = JSON.parse(sessionStorage.getItem("user"))
              .projectContractorId;
          }
          let data = this.addEngineer;
          data.projectId = projectId;
            this.$axios.get("/shop/create", { params: data }).then(res => {
              if (res.data.state === 1) {
                this.pageDatalist.pageNum=1;
                this.getMerchantDate(this.pageDatalist);
                this.DeleteSuccess();
                this.dialogForm = false;
              } else {
                this.DeleteFailure(res.data.msg);
              }
              this.addEngineer.shopName = "";
              this.addEngineer.address = "";
              this.addEngineer.mobile = "";
              this.addEngineer.remark = "";
              this.addEngineer.engineerValue = "";
            });
          } else {
            return false;
          }
        });
    },
    remoteMethod(inputData){
      inputData!=='' && this.getEngineer(inputData);
    },
    //获取工程商
    getEngineer(params) {
      this.$axios.get("projectContractor/queryAll",{params:{projectName:params}}).then(res => {
        this.engineer = res.data.projectTbPage; //返回数据
      });
    },
    //资料补全
    DataCompletion(row) {
      this.CompletionMan = row;
      this.$axios
        .get("/shop/queryMaterialLog", {
          params: { shopId: row.id, projectId: row.projectContractorId }
        })
        .then(res => {
          this.SubmitData = res.data.shopMaterialTbPage.map(item => {
            item.createTime = this.getTiem(item.createTime);
            return item;
          });
          this.dialogTabledata = true;
        });
    },
    //下载
    downloadData(item) {
      this.$axios
        .get("/shop/downloadMaterial", {
          params: {
            shopId: item.shopId,
            projectId: item.projectContractorId,
            fileId: item.id
          },
          responseType: "blob"
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          let _fileName = res.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1]
            .split(".")[0];
          // 文件名中有中文 则对文件名进行转码
          a.download = decodeURIComponent(_fileName);
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        });
    },
    // 文件上传
    submitUpload() {
      this.$refs.upload.submit();
    },

    //设置员工页面跳转
    setEngineer(row) {
      this.switchPag = 2;
      this.managementData = row;
      this.getEmployee();
    },
    //设置渠道页面跳转
    CollectionChannels(index, row) {
      this.CollectionChannelsData = row;
      this.getCollectionChannels();
      this.switchPag = 1;
    },
    // 返回按钮
    ReturnEdit() {
      this.switchPag = 0;
      this.alipay = [];
      this.weChat = [];
    },

    // 员工页面
    //获取员工
    getEmployee() {
      this.$axios
        .get("/member/query", {
          params: {
            projectContractorId: this.managementData.projectContractorId,
            level: 3,
            shopId: this.managementData.id
          }
        })
        .then(res => {
          let EmployeeList = res.data.userInfoTbPage;
          let number = 0; //定义编号变量
          EmployeeList.map(item => {
            const roleTbMap = res.data.roleTbMap;
            //根据返回的roleId找到对应的name
            item.NewroleId = item.roleId;
            const roleId = item.roleId + "";
            item.roleId = roleTbMap[roleId];
            number++;
            item.num = number;
            item.createTime = this.getTiem(item.createTime);
            if (item.state === 1) {
              item.state = "可用";
            } else if (item.state === 0) {
              item.state = "不可用";
            } else {
              item.state = "禁用";
            }
            item.loginAccount = getToken().userName;
            return item;
          });
          this.EmployeeData = EmployeeList;
        });
    },
    //编辑员工
    EmployeeEdit(row) {
      this.EmployeeEdits = true;
      this.EmployeesList = { ...row };
    },
    //确认编辑
    DetermineEdit() {
      const { id, mobile, nickname, password, NewroleId } = this.EmployeesList;
      if (nickname !== "" && password !== "") {
        this.$axios
          .get("/member/edit", {
            params: {
              roleId: NewroleId,
              mobile: mobile.trim(),
              nickName: nickname.trim(),
              userId: id,
              passWord: password
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.EmployeeEdits = false;
              this.getEmployee();
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    //创建员工
    addEmployees() {
      this.FormVisible = true;
    },
    //确认创建员工
    addPersonnel() {
      const { nickName, mobile, password } = this.addPersonnelData;
      if (nickName !== "" && mobile !== "" && password !== undefined) {
        let pwds = this.$md5(password.trim()).toUpperCase();
        const { projectContractorId, id } = this.managementData;
        this.$axios
          .get("/member/create", {
            params: {
              nickName: nickName.trim(),
              mobile: mobile.trim(),
              password: pwds.trim(),
              projectContractorId,
              shopId: id,
              level: 3
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.addPersonnelData.nickName = "";
              this.addPersonnelData.mobile = "";
              this.addPersonnelData.password = "";
              this.DeleteSuccess();
              this.getEmployee();
              this.FormVisible = false;
            } else {
              this.addPersonnelData.nickName = "";
              this.addPersonnelData.mobile = "";
              this.addPersonnelData.password = "";
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    //确认删除员工
    DelSure() {
      this.$axios
        .post("/member/delete?userId=" + this.Employees.id)
        .then(res => {
          if (res.data.state === 1) {
            this.DeleteSuccess();
            this.getEmployee();
            this.dialogVisibleDelete = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //修改密码
    setPwd(row) {
      this.dialogVisibleSet = true;
      this.pwdEdit = { ...row };
    },
    //确定修改密码
    SureSetPwd() {
      const { password, id } = this.pwdEdit;
      if (this.pwd === this.pwdTwo && this.pwd !== "") {
        let pwds = this.$md5(this.pwd.trim()).toUpperCase();
        this.$axios
          .get("/member/reSetPw", {
            params: {
              userId: id,
              passWord: pwds.trim()
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.pwd = "";
              this.pwdTwo = "";
              this.dialogVisibleSet = false;
            } else {
              this.pwd = "";
              this.pwdTwo = "";
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.PasswordError();
      }
    },
    // 密码错误提示
    PasswordError() {
      this.$notify.error({
        title: "错误",
        message: "密码输入错误，请重新输入！"
      });
    },
    //表格颜色显示
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "success-row";
      }
      return "";
    },

    // 收款渠道
    // 查询商户收款渠道
    getCollectionChannels(row) {
      const { id, projectContractorId } = this.CollectionChannelsData;
      this.$axios
        .get("/shop/mch", {
          params: { projectId: projectContractorId, shopId: id }
        })
        .then(res => {
          if (res.data.state === 1) {
            let weChatPage = res.data.mchsTbPage.wx;
            let alipayPage = res.data.mchsTbPage.ali;
            let yzPage = res.data.mchsTbPage.yz;
            let ksPage = res.data.mchsTbPage.ksb;

            // 微信
            if (weChatPage !== undefined) {
              if (weChatPage.examined === -1) {
                weChatPage.examined = "未审核";
              } else if (weChatPage.examined === 0) {
                weChatPage.examined = "审核中";
              } else if (weChatPage.examined === 1) {
                weChatPage.examined = "审核通过";
              }
              if (weChatPage.authorized === 1) {
                weChatPage.authorized = "是";
              } else if (weChatPage.authorized === 0) {
                weChatPage.authorized = "否";
              }
              if (weChatPage.state === 1) {
                weChatPage.state = "可用";
              } else if (weChatPage.state === 0) {
                weChatPage.state = "不可用";
              } else if (weChatPage.state === -1) {
                weChatPage.state = "禁用";
              }
              if (weChatPage.createTime !== undefined) {
                weChatPage.createTime = this.getTiem(weChatPage.createTime);
              }
              if (weChatPage !== "") {
                this.weChat = [weChatPage];
              }
            }
            if (alipayPage !== undefined) {
              // 支付宝
              if (alipayPage.examined === -1) {
                alipayPage.examined = "未审核";
              } else if (alipayPage.examined === 0) {
                alipayPage.examined = "审核中";
              } else if (alipayPage.examined === 1) {
                alipayPage.examined = "审核通过";
              }
              if (alipayPage.authorized === 1) {
                alipayPage.authorized = "是";
              } else if (alipayPage.authorized === 0) {
                alipayPage.authorized = "否";
              }
              if (alipayPage.state === 1) {
                alipayPage.state = "可用";
              } else if (alipayPage.state === 0) {
                alipayPage.state = "不可用";
              } else if (alipayPage.state === -1) {
                alipayPage.state = "禁用";
              }
              if (alipayPage.createTime !== undefined) {
                alipayPage.createTime = this.getTiem(alipayPage.createTime);
              }
              if (alipayPage !== "") {
                this.alipay = [alipayPage];
              }
            }
            if (yzPage !== undefined) {
              // 云纵
              if (yzPage.examined === -1) {
                yzPage.examined = "未审核";
              } else if (yzPage.examined === 0) {
                yzPage.examined = "审核中";
              } else if (yzPage.examined === 1) {
                yzPage.examined = "审核通过";
              }
              if (yzPage.authorized === 1) {
                yzPage.authorized = "是";
              } else if (yzPage.authorized === 0) {
                yzPage.authorized = "否";
              }
              if (yzPage.state === 1) {
                yzPage.state = "可用";
              } else if (yzPage.state === 0) {
                yzPage.state = "不可用";
              } else if (yzPage.state === -1) {
                yzPage.state = "禁用";
              }
              if (yzPage.createTime !== undefined) {
                yzPage.createTime = this.getTiem(yzPage.createTime);
              }
              if (yzPage !== "") {
                this.yzData = [yzPage];
              }
            }

            if (ksPage !== undefined) {
              // 昆山
              if (ksPage.examined === -1) {
                ksPage.examined = "未审核";
              } else if (ksPage.examined === 0) {
                ksPage.examined = "审核中";
              } else if (ksPage.examined === 1) {
                ksPage.examined = "审核通过";
              }
              if (ksPage.authorized === 1) {
                ksPage.authorized = "是";
              } else if (ksPage.authorized === 0) {
                ksPage.authorized = "否";
              }
              if (ksPage.state === 1) {
                ksPage.state = "可用";
              } else if (ksPage.state === 0) {
                ksPage.state = "不可用";
              } else if (ksPage.state === -1) {
                ksPage.state = "禁用";
              }
              if (ksPage.createTime !== undefined) {
                ksPage.createTime = this.getTiem(ksPage.createTime);
              }
              this.ksbData = [ksPage];
            }
          }
        });
    },
    //微信直连编辑
    weChatEdit(row) {
      this.CollectMoney = true;
      this.weChatEditData = { ...row };
      this.disabled = true;
      if (this.$store.state.user.level === 1) {
        //只有系统管理员才能更改商户号
        this.wxdisabledData = false;
      }
    },
    //确认编辑微信直连
    weChatSureEdit() {
      const {
        id,
        shopId,
        projectContractorId,
        subMchId,
        mchName,
        authorized
      } = this.weChatEditData;
      this.$axios
        .get("/mchwx/edit", {
          params: {
            mchName,
            mchId: id,
            shopId,
            projectId: projectContractorId,
            wxSubMchId: subMchId,
            authorized: authorized == "是" ? 1 : 0
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.CollectMoney = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //删除微信直连
    weChatDelete(row) {
      this.weChatDelData = row;
      this.dialogDelete = true;
    },
    //确认删除
    weChatSureDel() {
      const { id, shopId, projectContractorId } = this.weChatDelData;
      this.$axios
        .get("/mchwx/delete", {
          params: {
            shopId,
            mchId: id,
            projectId: projectContractorId
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.dialogDelete = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //编辑支付宝直连
    alipayEdit(row) {
      this.alipayCollectMoney = true;
      this.alipayEditData = { ...row };
      if (this.$store.state.user.level === 1) {
        //只有系统管理员才能更改商户号
        this.apliydisabledData = false;
      }
    },
    //确认编辑支付宝直连
    alipaySureEdit() {
      const {
        projectContractorId,
        mchName,
        mchId,
        appAuthToken,
        authorized,
        id,
        shopId
      } = this.alipayEditData;
      this.$axios
        .get("/mchali/edit", {
          params: {
            shopId,
            mchId: id,
            projectId: projectContractorId,
            mchName,
            aliMchId: mchId,
            appAuthToken,
            authorized: authorized == "是" ? 1 : 0
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.alipayCollectMoney = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //删除支付宝直连
    alipayDelete(row) {
      this.alipaydialogDelete = true;
      this.alipayDelData = row;
    },
    //确认删除支付宝直连
    alipaySureDel() {
      const { id, shopId, projectContractorId } = this.alipayDelData;
      this.$axios
        .get("/mchali/delete", {
          params: {
            mchId: id,
            shopId,
            projectId: projectContractorId
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.alipaydialogDelete = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //编辑云纵
    yzEdit(row) {
      this.yzCollectMoney = true;
      this.yzEditData = { ...row };
      if (this.$store.state.user.level === 1) {
        //只有系统管理员才能更改商户号
        this.yzdisabledData = false;
      }
    },
    // 确认编辑云纵
    yzSureEdit() {
      const {
        projectContractorId,
        mchName,
        subMchId,
        authorized,
        id,
        shopId
      } = this.yzEditData;
      this.$axios
        .get("/mchyz/edit", {
          params: {
            shopId,
            mchId: id,
            projectId: projectContractorId,
            mchName,
            subMchId,
            authorized: authorized == "是" ? 1 : 0
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.yzCollectMoney = false;
          } else {
            this.DeleteFailure(res.data.msg);
            this.yzCollectMoney = false;
          }
        });
    },
    // 删除云纵
    yzDelete(row) {
      this.yzdialogDelete = true;
      this.yzDelData = row;
    },
    // 确定删除云纵
    yzSureDel() {
      const { id, shopId, projectContractorId } = this.yzDelData;
      this.$axios
        .get("/mchyz/delete", {
          params: {
            mchId: id,
            shopId,
            projectId: projectContractorId
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getCollectionChannels();
            this.DeleteSuccess();
            this.yzdialogDelete = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 高级搜索显示切换
    showInput() {
      this.show = !this.show;
      if (this.show === true) {
        this.disableds = true;
      } else {
        this.disableds = false;
      }
    },

    // 变更服务商
    async uploadProject(row) {
      this.newProjectId=row.projectContractorId;
      this.uploadProData=row;
      let projectAll = await this.$axios.post("/projectContractor/queryAll");
      this.projectAllData = projectAll.data.projectTbPage;
      this.dialogUploadProject = true;
    },
    // 确认变更
    confirmUpload() {
      const {id,projectContractorId} = this.uploadProData;
      this.$axios.get("/shop/editShopProject",{params:{shopId:id,projectId:projectContractorId,newProjectId:this.newProjectId}})
      .then(res=>{
        if(res.data.state==1){
          this.pageDatalist.pageNum=1;
          this.getMerchantDate(this.pageDatalist);
          this.$message({message:res.data.msg,type: "success"});
          this.dialogUploadProject = false;
        }else{
          this.$message({message:res.data.msg,type: "error"});
        }
      })
    }
  }
};
</script>
<style>
.Operation > span {
  color: rgb(58, 58, 211);
  margin-left: 5px;
}
.Operation > span:first-child {
  color: red;
}
.pag {
  position: fixed;
  bottom: 40px;
  right: 10px;
}
.el-table .cell {
  text-align: center;
}

.el-dialog__title {
  color: white !important;
  font-weight: 600;
  font-size: 22px;
}
.el-select {
  width: 100%;
}
.el-table td {
  padding: 10px 0;
}
.down {
  border-bottom: 1px solid;
}
.down:hover {
  color: rgb(86, 86, 231);
}
.el-button--mini {
  padding: 7px 7px;
}
.el-button--mini {
  padding: 5px 5px;
}
.el-input {
  width: 93%;
}
.tabelhader {
  margin-bottom: 10px;
  font-weight: 600;
}
.tabelhader > svg {
  vertical-align: middle;
}
.tabelhader > img {
  vertical-align: middle;
}
#add > button {
  position: fixed;
  right: 20px;
  margin-right: 0.5%;
}
.default {
  margin-left: 20px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.el-form-item__label {
  text-align: center;
}

.paytype {
  width: 100%;
  display: flex;
  margin: auto;
}
.paytype > div:first-child {
  width: 175px;
  text-align: center;
  font-weight: 600;
  line-height: 45px;
}
.paytype > div:nth-child(2) {
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.paytype > div:nth-child(2) > label {
  margin-top: 15px;
}
.editShop {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>

